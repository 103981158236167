import React, { useEffect, useState } from "react";
import { AiTwotoneCalendar } from "react-icons/ai";
import { NavLink, useNavigate } from "react-router-dom";
import ManmohanPhoto from "../components/assets/images/my photo.jpg";

export const Blog = () => {
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const response = await fetch(process.env.REACT_APP_SAVE_BLOG, {
          method: "GET",
          headers: { "Content-Type": "application/json" },
        });

        if (!response.ok) throw new Error("Failed to fetch blogs.");

        const data = await response.json();
        setBlogs(data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching blogs:", error);
        setError(error.message);
        setLoading(false);
      }
    };

    fetchBlogs();
  }, []);

  const handleBlogClick = (id) => {
    navigate(`/blog/${id}`); // Navigate to blog details page on click
  };

  if (loading)
    return (
      <p>
        {" "}
        <div className="main-loading">
          <div class="spacecraft-loading">
            <span>L</span>
            <span>O</span>
            <span>A</span>
            <span>D</span>
            <span>I</span>
            <span>N</span>
            <span>G</span>
            <div class="orbit"></div>
          </div>
        </div>
      </p>
    );
  if (error) return <p>Error: {error}</p>;

  return (
    <section className="courses">
      <div className="w-4/5 m-auto">
        <div className="heading text-center pb-12">
          <h1 className="text-3xl font-semibold text-black">
            We Share <br /> Our Blogs And News
          </h1>
          <span className="text-sm mt-2 block">
            You don't have to struggle alone; we're here to assist and support
            your journey toward knowledge and growth..
          </span>
        </div>
        <div className="grid grid-cols-3 gap-5 md:grid-cols-1">
          {blogs.map((item) => (
            <div
              key={item.id}
              className="box rounded-lg shadow-lg bg-white"
              onClick={() => handleBlogClick(item.id)} // Navigate on click
            >
              <div className="images rounded-t-lg overflow-hidden h-40">
                <img
                  src={`${process.env.REACT_APP_BASE_URL}${item.image_path}`}
                  alt={item.title}
                  className="rounded-t-lg object-cover w-full h-full transition-transform duration-300 hover:scale-110 cursor-pointer"
                />
              </div>
              <div className="text p-4">
                <div
                  className="keywords flex flex-wrap"
                  style={{ gap: "10px", marginBottom: "10px" }}
                >
                  {item.keywords.split(",").map((keyword, index) => (
                    <span
                      key={index}
                      style={{
                        backgroundColor: "#374151",
                        color: "white",
                        padding: "5px 10px",
                        borderRadius: "8px",
                        fontSize: "14px",
                      }}
                    >
                      {keyword.trim()}
                    </span>
                  ))}
                </div>

                <NavLink to={`/blog/${item.id}`}>
                  <h3 className="text-lg font-medium my-4">{item.title}</h3>
                </NavLink>
                <div className="user flex justify-between items-center">
                  <div className="flex items-center">
                    <img
                      className="w-8 h-8 rounded-full"
                      src={ManmohanPhoto}
                      alt="Author"
                    />
                    <span className="ml-2 text-sm">Admin</span>
                  </div>
                  <div className="flex items-center">
                    <AiTwotoneCalendar />
                    {new Date(item.created_at).toLocaleDateString()}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};
