import React, { useEffect, useState } from "react";
import "./FreeJob.css";

const FreeJob = () => {
  const [jobData, setJobData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [headerOptions, setHeaderOptions] = useState([]);
  const [selectedHeader, setSelectedHeader] = useState("Haryana");

  useEffect(() => {
    // Fetch data from API
    const fetchJobs = async () => {
      try {
        const response = await fetch(
          "https://notesmafia.taazaekhabar.site/freejob-api.php"
        );
        if (!response.ok) {
          throw new Error("Failed to fetch jobs data");
        }
        const data = await response.json();

        // Extract headers for dropdown
        const headers = data.map((region) => region.Header);
        setHeaderOptions(headers);

        // Automatically filter for the default header
        setFilteredData(
          data.filter((region) =>
            region.Header.toLowerCase().includes("haryana".toLowerCase())
          )
        );

        setJobData(data);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchJobs();
  }, []);

  const handleFilter = () => {
    const filtered = jobData.filter(
      (region) => region.Header.toLowerCase() === selectedHeader.toLowerCase()
    );
    setFilteredData(filtered);
  };

  return (
    <div className="freejob-container">
      {/* Banner Section */}
      <div className="banner">
        <div className="banner-content">
          <h1>Search Free Jobs</h1>
          <p>Find the latest job openings in your preferred location.</p>
          <div className="filter-container">
            <select
              value={selectedHeader}
              onChange={(e) => setSelectedHeader(e.target.value)}
            >
              {headerOptions.map((header, index) => (
                <option key={index} value={header}>
                  {header}
                </option>
              ))}
            </select>
            <button onClick={handleFilter}>Search</button>
          </div>
        </div>
      </div>

      {/* Display Filtered Jobs */}
      {loading && <div>Loading jobs...</div>}
      {error && <div>Error: {error}</div>}
      {!loading && !error && (
        <div className="video-grid">
          {filteredData.length === 0 ? (
            <div>No jobs found for the specified header.</div>
          ) : (
            filteredData.map((region, index) => (
              <div key={index} className="region-card">
                <h2>{region.Header}</h2>
                <div>
                  {region.Jobs.map((job, jobIndex) => (
                    <div key={jobIndex} className="job-card">
                      <h3>{job["Post Name"]}</h3>
                      <p>
                        <strong>Post Date:</strong> {job["Post Date"]}
                      </p>
                      <p>
                        <strong>Recruitment Board:</strong>{" "}
                        {job["Recruitment Board"]}
                      </p>
                      <p>
                        <strong>Qualification:</strong> {job.Qualification}
                      </p>
                      <p>
                        <strong>Advt No:</strong> {job["Advt No"]}
                      </p>
                      <p>
                        <strong>Last Date:</strong> {job["Last Date"]}
                      </p>
                      <a
                        href={job["More Information"]}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        More Information
                      </a>
                    </div>
                  ))}
                </div>
              </div>
            ))
          )}
        </div>
      )}
    </div>
  );
};

export default FreeJob;
