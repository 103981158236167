import React, { useState, useContext } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import ProgressBar from "@ramonak/react-progress-bar";
import { useNavigate } from "react-router-dom";
import { Bars } from "react-loader-spinner";
import InfoTextCard from "../../components/common/InfoCard/InfoCard";
import { GloblInfo } from "../../App"; // Global context for user session

const FileUploadAll = () => {
  const { userSession } = useContext(GloblInfo); // User session from global context
  const [file, setFile] = useState(null);
  const [fileTitle, setFileTitle] = useState("");
  const [fileDescription, setFileDescription] = useState("");
  const [tags, setTags] = useState([]);
  const [tagInput, setTagInput] = useState("");
  const [category, setCategory] = useState("");
  const [customCategory, setCustomCategory] = useState(""); // For user-defined category
  const [progressPercent, setProgressPercent] = useState(0);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  // Handle file input change
  const handleFileChange = (e) => setFile(e.target.files[0]);

  // Add a tag (max 5 tags)
  const handleAddTag = () => {
    if (tagInput && tags.length < 5) {
      setTags([...tags, tagInput]);
      setTagInput("");
    }
  };

  // Remove a tag
  const handleRemoveTag = (tagToRemove) =>
    setTags(tags.filter((tag) => tag !== tagToRemove));

  const handleUpload = async (e) => {
    e.preventDefault();

    // Check if all fields are filled
    if (
      !file ||
      !fileTitle ||
      !fileDescription ||
      (!category && !customCategory)
    ) {
      toast.error("Please fill all the fields.");
      return;
    }

    const formData = new FormData();
    formData.append("file", file);
    formData.append("title", fileTitle);
    formData.append("description", fileDescription);
    formData.append("tags", tags.join(","));
    formData.append(
      "category",
      category === "Other" ? customCategory : category
    );
    formData.append("username", userSession.username);

    try {
      setLoading(true);

      const response = await axios.post(
        process.env.REACT_APP_UPLOAD_PDF,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          withCredentials: true,
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setProgressPercent(percentCompleted); // Update progress bar
          },
        }
      );

      const result = response.data;

      // Display success message
      if (result.message) {
        toast.success(result.message);
        console.log(result.message);
      }
      // Redirect to the user's profile page on success
      if (
        result.message === "File and first page image uploaded successfully."
      ) {
        navigate(`/profile/${userSession.username}`);
        resetForm(); // Clear the form inputs
      }
    } catch (error) {
      console.error("Error during file upload:", error);
      toast.error("An error occurred while uploading the file.");
    } finally {
      setLoading(false);
    }
  };

  // Reset form fields after successful upload
  const resetForm = () => {
    setFile(null);
    setFileTitle("");
    setFileDescription("");
    setTags([]);
    setCategory("");
    setCustomCategory("");
    setProgressPercent(0);
  };

  return (
    <div className="file-upload-container">
      <div className="file-upload-card">
        <h3>Upload Files</h3>

        <div className="file-dropbox">
          {file ? (
            <header>
              <h4>File Selected: {file.name}</h4>
            </header>
          ) : (
            <>
              <header>
                <h4>Select File here</h4>
              </header>
              <input
                type="file"
                accept="*"
                onChange={handleFileChange}
                id="fileInputID"
                style={{ display: "none" }}
              />
              <label htmlFor="fileInputID" className="upload-btn">
                Choose File
              </label>
            </>
          )}
        </div>

        <div className="form-input-section">
          <div className="form-input-group">
            <label htmlFor="fileTitleInput">File Title</label>
            <input
              type="text"
              id="fileTitleInput"
              placeholder="File Title"
              onChange={(e) => setFileTitle(e.target.value)}
              value={fileTitle}
              required
            />
          </div>
          <div className="form-input-group">
            <label htmlFor="fileDescriptionInput">File Description</label>
            <textarea
              id="fileDescriptionInput"
              placeholder="File Description"
              onChange={(e) => setFileDescription(e.target.value)}
              value={fileDescription}
              required
            />
          </div>

          {/* Category Input */}
          <div className="form-input-group">
            <label htmlFor="categorySelect">Select Category</label>
            <select
              id="categorySelect"
              value={category}
              onChange={(e) => setCategory(e.target.value)}
              required
            >
              <option value="">Select Category</option>
              <option value="Banking">Banking</option>
              <option value="Competition">Competition Exam</option>
              <option value="Legal">Legal</option>
              <option value="Other">Other</option>
            </select>
          </div>

          {/* Custom Category Input (Conditionally Rendered) */}
          {category === "Other" && (
            <div className="form-input-group">
              <label htmlFor="customCategoryInput">Enter Your Category</label>
              <input
                type="text"
                id="customCategoryInput"
                placeholder="Enter custom category"
                onChange={(e) => setCustomCategory(e.target.value)}
                value={customCategory}
                required
              />
            </div>
          )}

          {/* Tags Input */}
          <div className="form-input-group tag-input-group">
            <label htmlFor="tagInput">Add Tag (Max 5)</label>
            <InfoTextCard tags={tags} onRemoveTag={handleRemoveTag} />
            <span style={{ display: "flex", gap: "20px" }}>
              <input
                type="text"
                value={tagInput}
                onChange={(e) => setTagInput(e.target.value)}
                placeholder="Enter tag"
              />
              <button className="upload-btn" onClick={handleAddTag}>
                Add
              </button>
            </span>
          </div>

          {/* Progress Bar and Upload Button */}
          <div className="form-input-group tag-input-group">
            <ProgressBar
              completed={progressPercent}
              height="15px"
              bgColor="#005af0"
            />
            <button
              className="upload-btn"
              onClick={handleUpload}
              disabled={loading}
            >
              {loading ? "Uploading..." : "Upload File"}
            </button>
          </div>
        </div>
      </div>

      {loading && (
        <div className="loading-bar">
          <Bars color="#005af0" />
        </div>
      )}
    </div>
  );
};

export default FileUploadAll;
