import React, { useState, createContext, useEffect } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Layout } from "./components/common/Layout";
import { Home } from "./pages/Home";
import { BlogSinglePage } from "./components/common/BlogSinglePage";
import { About } from "./pages/About";
import { Courses } from "./pages/Courses";
import { Blog } from "./pages/Blog";
import { Instructor } from "./pages/Instructor";
import LoginSignUp from "./pages/auth/LoginSignUp";
import Profile from "./pages/profile/Profile";
import EditProfile from "./pages/profile/EditProfile";
import { UserProvider } from "./context/UserContext";
import FileUpload from "./pages/FileUpload/FileUpload";
import PdfDetails from "./components/common/PdfDetails/PdfDetails";
import EditPdf from "./pages/EditPdf/EditPdf";
import SearchResults from "./pages/SearchResults";
import YouTubeSearch from "./pages/YouTubeSearch";
import GoogleBooksSearch from "./pages/GoogleBooksSearch";
import { BlogDetails } from "./pages/BlogDetails";
import { PrivacyPolicy } from "../src/pages/PrivacyPolicy/PrivacyPolicy";
import ContactUs from "./pages/ContactUs/ContactUs";
import MyTeam from "./pages/MyTeam";
import ScrollToTop from "./components/common/ScrollToTop";
import Quiz from "./components/common/Quiz/Quiz";
import FileUploadAll from "./pages/FileUpload/FileUploadAll";
import FreeJob from "./pages/FreeJob/FreeJob";
import ChatWithAI from "./pages/ChatWithAI/ChatWithAI";

const API_URL = process.env.REACT_APP_ONLINE_UERS; // Change to your backend URL
export const GloblInfo = createContext();

function App() {
  const [userSession, setUserSession] = useState(null);
  const [uploads, setUploads] = useState([]); // State to store data
  const [userData, setUserData] = useState(null);
  const [previewImage, setPreviewImage] = useState("img/user.jpeg"); // State to hold profile image
  const [users, setUsers] = useState([]); // State to hold user data
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState(null); // Error state
  const [onlineUsers, setOnlineUsers] = useState(0);
  const [activeUsers, setActiveUsers] = useState(0);

  // Increment the online user count
  const incrementOnlineUsers = async () => {
    try {
      await fetch(API_URL, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ action: "increment" }),
      });
      fetchOnlineUsers();
    } catch (err) {
      console.error("Error incrementing user count:", err);
    }
  };

  // Decrement the online user count
  const decrementOnlineUsers = async () => {
    try {
      await fetch(API_URL, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ action: "decrement" }),
      });
      fetchOnlineUsers();
    } catch (err) {
      console.error("Error decrementing user count:", err);
    }
  };

  // Fetch the current online user count
  const fetchOnlineUsers = async () => {
    try {
      const response = await fetch(API_URL);
      const data = await response.json();
      setOnlineUsers(data.count);
    } catch (err) {
      console.error("Error fetching user count:", err);
    }
  };

  useEffect(() => {
    incrementOnlineUsers(); // Increment on page load

    // Decrement on tab close or reload
    window.addEventListener("beforeunload", decrementOnlineUsers);

    return () => {
      window.removeEventListener("beforeunload", decrementOnlineUsers);
    };
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(process.env.REACT_APP_GET_UPLOADS, {
          method: "GET",
          credentials: "include", // Ensure cookies and credentials are included
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json(); // Parse the JSON response
        setUploads(data); // Save data to the state
        setLoading(false); // Set loading to false after data is fetched
      } catch (err) {
        setError(err.message); // Handle any errors
        setLoading(false); // Stop loading if there's an error
      }
    };

    fetchData();
  }, []); // Empty dependency array means this will run once on mount

  // Fetch user data from the API
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await fetch(process.env.REACT_APP_GET_USERS);
        const result = await response.json();

        if (result.status === "success") {
          const users = result.data;
          setUserData(users);
        } else {
          console.error("Failed to retrieve user data");
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUsers();
  }, []); // Empty dependency array means this will run once on mount
  console.log("all user", userData);
  console.log("all pdf", uploads);

  useEffect(() => {
    const storedUser = localStorage.getItem("userSession");
    if (storedUser) {
      const parsedUser = JSON.parse(storedUser);
      setUserSession(parsedUser);
    }
  }, []);

  useEffect(() => {
    // Only fetch user data when userSession exists
    const fetchUserData = async () => {
      if (userSession) {
        try {
          const response = await fetch(process.env.REACT_APP_GET_USERS);
          const result = await response.json();

          if (result.status === "success") {
            const users = result.data;
            const matchedUser = users.find(
              (user) => user.id === userSession.id
            );

            if (matchedUser) {
              console.log("matchedUser", matchedUser);

              // Save user data in state
              setUserData(matchedUser);

              // Set profile image
              setPreviewImage(matchedUser.profile_image || "img/user.jpeg");
            } else {
              console.error("User not found");
            }
          } else {
            console.error("Failed to retrieve user data");
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      }
    };

    fetchUserData();
  }, [userSession]);

  console.log(userSession);

  // Conditionally log userSession if it's not null
  useEffect(() => {
    if (userSession) {
      console.log("app", userSession);
    }
  }, [userSession]);

  return (
    <GloblInfo.Provider
      value={{ userSession, setUserSession, userData, uploads, users }}
    >
      <BrowserRouter>
        <ScrollToTop />
        <ToastContainer />
        <Routes>
          <Route
            path="/"
            element={
              <Layout>
                <Home />
              </Layout>
            }
          />
          <Route
            path="/search"
            element={
              <Layout>
                <SearchResults />
              </Layout>
            }
          />
          <Route
            path="/Youtube"
            element={
              <Layout>
                <YouTubeSearch />
              </Layout>
            }
          />
          <Route
            path="/Google-ebook"
            element={
              <Layout>
                <GoogleBooksSearch />
              </Layout>
            }
          />
          <Route
            path="/blog/:id"
            element={
              <Layout>
                <BlogDetails />
              </Layout>
            }
          />
          <Route
            path="/my-team"
            element={
              <Layout>
                <MyTeam />
              </Layout>
            }
          />
          <Route
            path="/about-us"
            element={
              <Layout>
                <Instructor />
              </Layout>
            }
          />
          <Route
            path="Privacy-Policy"
            element={
              <Layout>
                <PrivacyPolicy />
              </Layout>
            }
          />
          <Route
            path="Contact"
            element={
              <Layout>
                <ContactUs />
              </Layout>
            }
          />
          <Route
            path="/about"
            element={
              <Layout>
                <About />
              </Layout>
            }
          />
          <Route
            path="/pdf/:id"
            element={
              <Layout>
                <PdfDetails />
              </Layout>
            }
          />
          <Route
            path="quiz"
            element={
              <Layout>
                <Quiz />
              </Layout>
            }
          />
          <Route
            path="/pdf/:id/edit"
            element={
              <Layout>
                <EditPdf />
              </Layout>
            }
          />
          <Route
            path="/file-upload"
            element={
              <Layout>
                <FileUpload />
              </Layout>
            }
          />
          <Route
            path="/ai"
            element={
              <Layout>
                <ChatWithAI />
              </Layout>
            }
          />
          <Route
            path="/free-job"
            element={
              <Layout>
                <FreeJob />
              </Layout>
            }
          />
          <Route
            path="/file-upload-all"
            element={
              <Layout>
                <FileUploadAll />
              </Layout>
            }
          />
          <Route
            path="/all-pdf-notes"
            element={
              <Layout>
                <About />
              </Layout>
            }
          />
          <Route
            path="/auth/login"
            element={
              <Layout>
                <LoginSignUp />
              </Layout>
            }
          />
          <Route
            path="/auth/signup"
            element={
              <Layout>
                <LoginSignUp />
              </Layout>
            }
          />
          <Route
            path="/instructor"
            element={
              <Layout>
                <Instructor />
              </Layout>
            }
          />
          <Route
            path="/blog"
            element={
              <Layout>
                <Blog />
              </Layout>
            }
          />
          <Route
            path="/profile/:username"
            element={
              <Layout>
                <Profile />
              </Layout>
            }
          />
          <Route
            path="/profile/:username/edit"
            element={
              <Layout>
                <EditProfile />
              </Layout>
            }
          />
          <Route
            path="/single-blog"
            element={
              <Layout>
                <BlogSinglePage />
              </Layout>
            }
          />
        </Routes>
      </BrowserRouter>
    </GloblInfo.Provider>
  );
}

export default App;
