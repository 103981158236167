import React from "react";
import karan_suthar from "../components/assets/images/karan_suthar.jpg";
import manmohan_suthar from "../components/assets/images/my photo.jpg";
import sanju from "../components/assets/images/sanju.jpg";

const MyTeam = () => {
  return (
    <>
      <>
        <div className="heading pt-20 text-center w-2/3 m-auto md:w-full">
          <h1 className="text-3xl font-semibold text-black">My Team</h1>
          <span className="text-[14px] mt-2 block">
            To Create Something New ....... !
          </span>
        </div>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>Responsive Our Team Section</title>
        {/* Font Awesome */}
        <link
          rel="stylesheet"
          href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css"
        />
        {/* Google Font */}
        <link
          href="https://fonts.googleapis.com/css2?family=Poppins:wght@300;500;600&display=swap"
          rel="stylesheet"
        />
        {/* Stylesheet */}
        <link rel="stylesheet" href="style.css" />
        <section>
          <div className="row">
            {/* Column 1*/}
            <div className="column">
              <div className="card">
                <div className="img-container">
                  <img src={sanju} />
                </div>
                <h3>Sanjeev Suthar</h3>
                <p>Founder</p>
                <div className="icons">
                  <a href="#">
                    <i className="fab fa-twitter" />
                  </a>
                  <a href="#">
                    <i className="fab fa-linkedin" />
                  </a>
                  <a href="#">
                    <i className="fab fa-github" />
                  </a>
                  <a href="#">
                    <i className="fas fa-envelope" />
                  </a>
                </div>
              </div>
            </div>
            {/* Column 2*/}
            <div className="column">
              <div className="card">
                <div className="img-container">
                  <img src={manmohan_suthar} />
                </div>
                <h3>Manmohan Suthar</h3>
                <p> Full Stack Web. Developer</p>
                <div className="icons">
                  <a href="#">
                    <i className="fab fa-twitter" />
                  </a>
                  <a href="#">
                    <i className="fab fa-linkedin" />
                  </a>
                  <a href="#">
                    <i className="fab fa-github" />
                  </a>
                  <a href="#">
                    <i className="fas fa-envelope" />
                  </a>
                </div>
              </div>
            </div>
            {/* Column 3*/}
            <div className="column">
              <div className="card">
                <div className="img-container">
                  <img src={karan_suthar} />
                </div>
                <h3>Karan Suthar</h3>
                <p>PHP Laravel Developer</p>
                <div className="icons">
                  <a href="#">
                    <i className="fab fa-twitter" />
                  </a>
                  <a href="#">
                    <i className="fab fa-linkedin" />
                  </a>
                  <a href="#">
                    <i className="fab fa-github" />
                  </a>
                  <a href="#">
                    <i className="fas fa-envelope" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    </>
  );
};

export default MyTeam;
