import React from "react";
import { FaGraduationCap, FaUsers } from "react-icons/fa";
import { GiEvilBook, GiWorld } from "react-icons/gi";
import TopUsers from "./TopUsers/TopUsers";

export const InstructorCard = (props) => {
  return (
    <div className={`box p-5 py-5 rounded-md`}>
      <div className="flex flex-col items-center justify-center text-center">
        {/* Icon and Number */}
        <div className="flex items-center justify-center gap-2">
          <div className={`${props.color} flex-shrink-0`}>{props.icon}</div>
          <h4 className="text-lg font-semibold text-black">{props.title}</h4>
        </div>

        {/* Description */}
        <p className="text-gray-500 mt-2">{props.desc}</p>
      </div>
    </div>
  );
};
export const Instructor = () => {
  return (
    <>
      <section className="instructor mb-16">
        <div className="container">
          <div className="heading py-12 text-center w-2/3 m-auto md:w-full">
            <h1 className="text-3xl font-semibold text-black">
              What is Notesmania?
            </h1>
            <span className="text-[14px] mt-2 block">
              Notesmania: Your go-to platform for effortless learning and
              teaching. Join a vibrant community where knowledge meets
              opportunity, making education accessible for all.
            </span>
          </div>
          <div className="content grid grid-cols-2 gap-5 md:grid-cols-1">
            <div className="videos rounded-lg overflow-hidden h-72 w-full">
              <iframe
                src="https://www.youtube.com/embed/KeeMLLNpT54"
                title="YouTube video"
                className="rounded-t-lg object-cover w-full h-72"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>

            <div className="videos rounded-lg overflow-hidden h-72 w-full">
              <iframe
                src="https://www.youtube.com/embed/sYgX96T6xew"
                title="YouTube video"
                className="rounded-t-lg object-cover w-full h-72"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          </div>

          <div className="content flex flex-col items-center pt-12">
            {/* Heading Section */}
            <div className="heading text-center w-2/3 md:w-full">
              <h1 className="text-3xl font-semibold text-black">
                We Are Proud
              </h1>
              <span className="text-[14px] mt-2 block">
                Thank you for your contribution! With your support, we’re
                growing stronger and achieving greater heights together.
              </span>
            </div>

            {/* Cards Section */}
            <div className="content grid grid-cols-4 gap-5 md:grid-cols-2 mt-8">
              <div className="flex flex-col items-center justify-center">
                <InstructorCard
                  color="text-red-500"
                  icon={<FaUsers size={40} />}
                  title="63"
                  desc="Students Enrolled"
                />
              </div>
              <div className="flex flex-col items-center justify-center">
                <InstructorCard
                  color="text-orange-500"
                  icon={<GiEvilBook size={40} />}
                  title="20"
                  desc="Total Courses"
                />
              </div>
              <div className="flex flex-col items-center justify-center">
                <InstructorCard
                  color="text-purple-500"
                  icon={<FaGraduationCap size={40} />}
                  title="4"
                  desc="Online Learners"
                />
              </div>
              <div className="flex flex-col items-center justify-center">
                <InstructorCard
                  color="text-indigo-500"
                  icon={<GiWorld size={40} />}
                  title="4"
                  desc="Online Learners"
                />
              </div>
            </div>
            <div className="content flex flex-col items-center pt-12">
              <TopUsers />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
