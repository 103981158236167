export const LinkData = [
  {
    id: 1,
    title: "Home",
    url: "/",
  },

  {
    id: 2,
    title: "All Material",
    url: "/all-pdf-notes",
  },
  {
    id: 3,
    title: "Quiz",
    url: "/Quiz",
  },

  {
    id: 4,
    title: "Video Search",
    url: "/Youtube",
  },
  {
    id: 5,
    title: "job Alert",
    url: "/free-job",
  },
  {
    id: 6,
    title: "Blog",
    url: "/blog",
  },
  {
    id: 7,
    title: "About",
    url: "/my-team",
  },
  {
    id: 8,
    title: "Contact Us",
    url: "/contact",
  },
];
export const courses = [
  {
    id: 1,
    cover: "../images/course-2-1.jpg",
    title: "The Power of Podcast for Storytelling",
  },
  {
    id: 2,
    cover: "../images/course-4-1.jpg",
    title: "Fashion and Luxury Fashion in a Changing",
  },
  {
    id: 3,
    cover: "../images/course-5-1.jpg",
    title: "Creative writing through Storytelling",
  },
  {
    id: 4,
    cover: "../images/course-6-1.jpg",
    title: "Improving The Accessibility Of Your Markdown",
  },
  {
    id: 5,
    cover: "../images/course11.jpg",
    title: "Product Manager Learn the Skills & job.",
  },
  {
    id: 6,
    cover: "../images/course12.jpg",
    title: "The business Intelligence analyst Course 2022",
  },
];
