import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "./Login.css";
import { GloblInfo } from "../../App";
import ReCAPTCHA from "react-google-recaptcha";
// import LoginPhoto from "../../components/assets/images/loginpagephoto.png";

const LoginSignUp = () => {
  const { userSession, setUserSession } = useContext(GloblInfo);
  const [isSignUpActive, setIsSignUpActive] = useState(false);
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [otp, setOtp] = useState("");
  const [otpSent, setOtpSent] = useState(false); // New state to track OTP sent
  const [captchaToken, setCaptchaToken] = useState(null); // State to hold captcha token
  const navigate = useNavigate();

  const onCaptchaChange = (token) => {
    setCaptchaToken(token); // Set the captcha token when user completes reCAPTCHA
  };

  // On page load, check if user session exists in localStorage
  useEffect(() => {
    const storedUserSession = localStorage.getItem("userSession");
    if (storedUserSession) {
      setUserSession(JSON.parse(storedUserSession)); // set state from localStorage
    }
  }, [setUserSession]); // Dependency array should include setUserSession

  const toggleForm = () => {
    setIsSignUpActive(!isSignUpActive);
    setErrorMessage(""); // Clear error message when toggling forms
  };

  const handleLogin = async (e) => {
    e.preventDefault();

    if (!captchaToken) {
      setErrorMessage("Please complete the CAPTCHA.");
      return;
    }

    try {
      const response = await fetch(process.env.REACT_APP_AUTH, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ login: true, email, password, captchaToken }),
      });

      if (!response.ok) {
        setErrorMessage("Login failed. Please try again.");
        toast.error("Login failed. Please try again.");
        console.error("Login error:", response.status, response.statusText);
        return;
      }

      const data = await response.json();

      if (data.status === "success") {
        // Check otp_status
        if (data.data.otp_status === 1) {
          setErrorMessage("Please verify your email before logging in.");
          toast.warn("Please verify your email before logging in.");
          return;
        }

        // Set the user session in both global state and localStorage
        const userSessionData = {
          id: data.data.id,
          username: data.data.username,
          email: data.data.email,
          login: true,
        };
        setUserSession(userSessionData); // Set in global state
        localStorage.setItem("userSession", JSON.stringify(userSessionData)); // Set in localStorage

        toast.success("Login successful!");
        navigate("/");
      } else if (
        data.message === "Please verify your email before logging in."
      ) {
        setErrorMessage(data.message);
        toast.warn(data.message);
      } else {
        setErrorMessage(data.message || "Login failed.");
        toast.error(data.message || "Login failed.");
        console.error("Login failed:", data);
      }
    } catch (error) {
      setErrorMessage("Error: " + error.message);
      toast.error("Error during login: " + error.message);
      console.error("Error during login:", error);
    }
  };

  // Handle Signup
  const handleSignup = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setErrorMessage("Passwords do not match!");
      return;
    }

    try {
      const response = await fetch(process.env.REACT_APP_AUTH, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ signup: true, name, email, password }),
      });

      const data = await response.json(); // Parse JSON response
      if (data.status === "success") {
        // Send OTP after successful signup
        const otpResponse = await fetch(process.env.REACT_APP_OTP_API, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ email }),
        });

        const otpData = await otpResponse.json();
        if (otpData.status === "success") {
          setOtpSent(true); // Set OTP sent status
          setErrorMessage(""); // Clear error message
          // alert("OTP has been sent to your email!");
          toast.success("OTP has been sent to your email!");
        } else {
          setErrorMessage("Failed to send OTP.");
        }
      } else {
        setErrorMessage(data.message || "Signup failed");
      }
    } catch (error) {
      setErrorMessage("Error: " + error.message);
    }
  };

  // Handle OTP Verification
  const handleVerifyOtp = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(process.env.REACT_APP_VERIFY_OTP, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email, otp }), // Send both email and OTP
      });

      const data = await response.json();
      if (data.status === "success") {
        toast.success("OTP verified successfully! You can now log in.");
        // alert("OTP verified successfully! You can now log in.");
        toggleForm(); // Switch to login form
      } else {
        setErrorMessage(data.message || "OTP verification failed");
      }
    } catch (error) {
      setErrorMessage("Error: " + error.message);
    }
  };

  // Handle Logout
  const handleLogout = async () => {
    try {
      const response = await fetch(process.env.REACT_APP_AUTH, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ logout: true }),
      });
      const data = await response.json();

      if (data.status === "success") {
        // Remove user session from local storage and state
        localStorage.removeItem("userSession");
        setUserSession(null);

        toast.success("Logged out successfully!");
        // alert("Logged out successfully!");
        navigate("/auth/login");
      } else {
        setErrorMessage("Logout failed");
      }
    } catch (error) {
      setErrorMessage("Error: " + error.message);
    }
  };

  return (
    <section className="auth_section">
      {userSession ? (
        // If logged in, show the profile page
        <div className="profile-container">
          <h2>Welcome, {userSession.username}</h2>
          <p>Email: {userSession.email}</p>
          <button onClick={handleLogout}>Logout</button>
        </div>
      ) : (
        // If not logged in, show the login/signup form
        <div className={`auth-container ${isSignUpActive ? "active" : ""}`}>
          {/* Sign In Form */}
          <div className="auth-user signin-box">
            <div className="image-box">
              <img
                src="https://i.pinimg.com/originals/04/eb/a4/04eba44b89ec18680db3a64e44f9c9e9.gif"
                // src={LoginPhoto}
                alt="Sign In Background"
              />
            </div>
            <div className="form-box">
              <form onSubmit={handleLogin}>
                <h2>Sign In</h2>
                <input
                  type="email"
                  name="email"
                  placeholder="Email Address"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
                <input
                  type="password"
                  name="password"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
                <ReCAPTCHA
                  sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY} // Set your site key
                  onChange={onCaptchaChange}
                />
                <input type="submit" value="Login" />
                {errorMessage && <p className="error">{errorMessage}</p>}
                <p className="switch-text">
                  Don't have an account?
                  <span onClick={toggleForm}>Sign Up.</span>
                </p>
              </form>
            </div>
          </div>

          {/* Sign Up Form */}
          <div className="auth-user signup-box">
            <div className="form-box">
              {!otpSent ? (
                // Show signup form when OTP not sent
                <form onSubmit={handleSignup}>
                  <h2>Create an account</h2>
                  <input
                    type="text"
                    name="name"
                    placeholder="Enter Your Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                  />
                  <input
                    type="email"
                    name="email"
                    placeholder="Email Address"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                  <input
                    type="password"
                    name="password"
                    placeholder="Create Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                  <input
                    type="password"
                    name="confirmPassword"
                    placeholder="Confirm Password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    required
                  />
                  <input type="submit" value="Sign Up" />
                  {errorMessage && <p className="error">{errorMessage}</p>}
                  <p className="switch-text">
                    Already have an account?
                    <span onClick={toggleForm}>Sign In.</span>
                  </p>
                </form>
              ) : (
                // Show OTP verification form after sending OTP
                <form onSubmit={handleVerifyOtp}>
                  <h2>Verify OTP</h2>
                  <input
                    type="text"
                    name="otp"
                    placeholder="Enter OTP"
                    value={otp}
                    onChange={(e) => setOtp(e.target.value)}
                    required
                  />
                  <input type="submit" value="Verify OTP" />
                  {errorMessage && <p className="error">{errorMessage}</p>}
                </form>
              )}
            </div>
            <div className="image-box">
              <img
                src="https://raw.githubusercontent.com/WoojinFive/CSS_Playground/master/Responsive%20Login%20and%20Registration%20Form/img2.jpg"
                alt="Sign Up Background"
              />
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

export default LoginSignUp;
