import React from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { GrDocumentNotes } from "react-icons/gr";
import { FaBook, FaRegFileArchive, FaGoogle } from "react-icons/fa";
import { IoNewspaperOutline } from "react-icons/io5";
import "./About.css";

export const About = () => {
  const navigate = useNavigate();

  const handleCardClick = (category) => {
    // Mapping the frontend category names to backend-expected format
    const categoryMap = {
      notes: "Notes",
      "old papers": "Old_Paper",
      books: "Books",
      projects: "Projects",
    };

    // Map to the backend format, defaulting to the original category if not found in the map
    const mappedCategory = categoryMap[category.toLowerCase()] || category;
    console.log(`Mapped Category clicked: ${mappedCategory}`); // Debugging line

    // Navigate to the search page with the correctly formatted category
    navigate(`/search?category=${encodeURIComponent(mappedCategory)}`);
  };

  const handleNavigateGoogle = () => {
    navigate("/Google-ebook");
  };

  return (
    <section className="about py-16">
      <div className="container">
        <div className="heading text-center py-12">
          <h1 className="text-3xl font-semibold text-black">
            Why NotesMania Stands Out
          </h1>
          <span className="text-sm mt-2 block">
            You don't have to struggle alone, you've got our assistance and
            help.
          </span>
        </div>
        <div className="about-card-container">
          <AboutCard
            color="bg-[#2D69F0]"
            icon={<GrDocumentNotes size={50} />}
            title="Notes"
            desc="4,000+ Notes"
            onClick={() => handleCardClick("notes")}
          />
          <AboutCard
            color="bg-[#DD246E]"
            icon={<IoNewspaperOutline size={50} />}
            title="Old Papers"
            desc="5,400+ Old Papers"
            onClick={() => handleCardClick("old papers")}
          />
          <AboutCard
            color="bg-[#8007E6]"
            icon={<FaBook size={50} />}
            title="Books"
            desc="400+ Books"
            onClick={() => handleCardClick("books")}
          />
          <AboutCard
            color="bg-[#0CAE74]"
            icon={<FaRegFileArchive size={50} />}
            title="Projects"
            desc="400+ Projects"
            onClick={() => handleCardClick("projects")}
          />
          <div onClick={handleNavigateGoogle}>
            <AboutCard
              color="bg-[#01a3a4]"
              icon={<FaGoogle size={50} />}
              title="Google E-Books"
              desc="1M+ Books"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export const AboutCard = (props) => (
  <div
    className={`box shadow-md p-5 py-8 rounded-md text-white ${props.color} cursor-pointer transition ease-in-out delay-150 hover:-translate-y-4 duration-300`}
    onClick={props.onClick}
  >
    <div className="icon">{props.icon}</div>
    <div className="text mt-5">
      <h4 className="text-lg font-semibold my-3">{props.title}</h4>
      <p className="text-sm">{props.desc}</p>
    </div>
  </div>
);

// Adding prop types for AboutCard component
AboutCard.propTypes = {
  color: PropTypes.string.isRequired,
  icon: PropTypes.element.isRequired,
  title: PropTypes.string.isRequired,
  desc: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};
