import React, { useEffect, useState } from "react";
import "./Quiz.css"; // Include the updated CSS file

const Quiz = () => {
  const [quizData, setQuizData] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [score, setScore] = useState(0);
  const [selectedOption, setSelectedOption] = useState(null);
  const [feedback, setFeedback] = useState(null);
  const [topic, setTopic] = useState(""); // Input for user to specify topic

  class Confettiful {
    constructor(el) {
      this.el = el;
      this.containerEl = null;

      this.confettiFrequency = 3;
      this.confettiColors = [
        "#EF2964",
        "#00C09D",
        "#2D87B0",
        "#48485E",
        "#EFFF1D",
      ];
      this.confettiAnimations = ["slow", "medium", "fast"];

      this._setupElements();
      this._renderConfetti();
    }

    _setupElements() {
      const containerEl = document.createElement("div");
      const elPosition = this.el.style.position;

      if (elPosition !== "relative" || elPosition !== "absolute") {
        this.el.style.position = "relative";
      }

      containerEl.classList.add("confetti-container");
      this.el.appendChild(containerEl);
      this.containerEl = containerEl;
    }

    _renderConfetti() {
      this.confettiInterval = setInterval(() => {
        const confettiEl = document.createElement("div");
        const confettiSize = Math.floor(Math.random() * 3 + 7) + "px";
        const confettiBackground =
          this.confettiColors[
            Math.floor(Math.random() * this.confettiColors.length)
          ];
        const confettiLeft =
          Math.floor(Math.random() * this.el.offsetWidth) + "px";
        const confettiAnimation =
          this.confettiAnimations[
            Math.floor(Math.random() * this.confettiAnimations.length)
          ];

        confettiEl.classList.add(
          "confetti",
          "confetti--animation-" + confettiAnimation
        );
        confettiEl.style.left = confettiLeft;
        confettiEl.style.width = confettiSize;
        confettiEl.style.height = confettiSize;
        confettiEl.style.backgroundColor = confettiBackground;

        confettiEl.removeTimeout = setTimeout(() => {
          confettiEl.parentNode.removeChild(confettiEl);
        }, 3000);

        this.containerEl.appendChild(confettiEl);
      }, 25);
    }
  }

  useEffect(() => {
    // Trigger confetti animation only when quiz is completed
    if (currentQuestion >= quizData.length && quizData.length > 0) {
      const containerEl = document.querySelector(".js-container");
      if (containerEl) {
        new Confettiful(containerEl);
      }
    }
  }, [currentQuestion, quizData.length]);

  const cleanJSON = (text) => {
    const start = text.indexOf("{");
    const end = text.lastIndexOf("}");
    return text.substring(start, end + 1);
  };

  const fetchData = async (topic) => {
    setLoading(true);
    setError(null);
    try {
      const response = await fetch(
        `https://notesmafia.taazaekhabar.site/quiz-api.php?topic=${encodeURIComponent(
          topic
        )}`
      );
      const text = await response.text();
      const jsonString = cleanJSON(text);
      const data = JSON.parse(jsonString);
      setQuizData(data.questions || []); // Default to an empty array if questions are not found
      setLoading(false);
    } catch (error) {
      console.error("Error fetching or parsing data:", error);
      setError("Failed to fetch or parse data.");
      setLoading(false);
    }
  };

  const handleAnswerSelect = (option) => {
    setSelectedOption(option);

    // Check if the selected option is correct or not
    if (option === quizData[currentQuestion].answer) {
      setScore((prev) => prev + 1);
      setFeedback("correct");
    } else {
      setFeedback("wrong");
    }
  };

  const handleNextQuestion = () => {
    // Move to the next question and reset states
    setSelectedOption(null);
    setFeedback(null);
    setCurrentQuestion((prev) => prev + 1);
  };

  const handleStartQuiz = () => {
    if (topic.trim()) {
      setQuizData([]);
      setScore(0);
      setCurrentQuestion(0);
      fetchData(topic);
    }
  };

  if (loading)
    return (
      <div>
        <div className="main-loading">
          <div className="spacecraft-loading">
            <span>L</span>
            <span>O</span>
            <span>A</span>
            <span>D</span>
            <span>I</span>
            <span>N</span>
            <span>G</span>
            <div className="orbit"></div>
          </div>
        </div>
      </div>
    );
  if (error) return <div>Error: {error}</div>;

  // Check if the current question exists in the quizData array
  const question = quizData[currentQuestion];

  // Show summary if the last question is answered
  if (!question && currentQuestion >= quizData.length && quizData.length > 0) {
    return (
      <div className="quiz-summary">
        <div
          className="js-container container-main"
          style={{ top: "0px" }}
        ></div>{" "}
        {/* Confetti container */}
        <div className="checkmark-circle">
          <div className="background"></div>
          <div className="checkmark draw"></div>
        </div>
        <div className="quiz-text-div">
          <h2 className="quiz-summary-title">Quiz Completed</h2>
          <p>
            Your Score: {score} / {quizData.length}
          </p>
          <button className="quiz-btn" onClick={() => window.location.reload()}>
            Play Again
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="quiz-app">
      {/* Input Box and Start Quiz Button */}
      {quizData.length === 0 && !loading && (
        <>
          <div className="quiz-card-container">
            <div className="quiz-setup">
              <input
                type="text"
                className="quiz-input"
                placeholder="Enter topic for quiz"
                value={topic}
                onChange={(e) => setTopic(e.target.value)}
              />
              <button
                className="quiz-btn"
                onClick={handleStartQuiz}
                disabled={!topic.trim()}
              >
                Start Quiz
              </button>
            </div>
            <h2 className="quiz-section-title">Top Quizzes</h2>
            <div className="quiz-cards">
              <div
                className="quiz-card-chip"
                onClick={() => {
                  setTopic("Movies Quiz");
                  handleStartQuiz();
                }}
              >
                <div className="quiz-card-content">
                  <h3>🎬 Movies Quiz</h3>
                  <p>Test your knowledge about popular movies.</p>
                </div>
              </div>
              <div
                className="quiz-card-chip"
                onClick={() => {
                  setTopic("Games Quiz");
                  handleStartQuiz();
                }}
              >
                <div className="quiz-card-content">
                  <h3>🎮 Games Quiz</h3>
                  <p>Challenge yourself with gaming trivia.</p>
                </div>
              </div>
              <div
                className="quiz-card-chip"
                onClick={() => {
                  setTopic("Computer Quiz");
                  handleStartQuiz();
                }}
              >
                <div className="quiz-card-content">
                  <h3>💻 Computer Quiz</h3>
                  <p>How much do you know about computers?</p>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {quizData.length > 0 && (
        <div className="quiz-card">
          <header className="quiz-header">
            <h3 className="quiz-title">Interactive Quiz</h3>
            <p className="quiz-progress">
              Question {currentQuestion + 1} of {quizData.length}
            </p>
          </header>

          {question && (
            <>
              <div className="quiz-question-container">
                <p className="quiz-question">{question.question}</p>
              </div>

              <div className="quiz-options-container">
                {question.options.map((option, index) => (
                  <div
                    key={index}
                    className={`quiz-option ${
                      selectedOption === option
                        ? feedback === "correct"
                          ? "quiz-option-correct" // Highlight selected correct option
                          : "quiz-option-wrong" // Highlight selected wrong option
                        : feedback === "wrong" && option === question.answer
                        ? "quiz-option-correct" // Highlight the correct answer in green if wrong answer selected
                        : ""
                    }`}
                    onClick={() =>
                      !selectedOption && handleAnswerSelect(option)
                    } // Prevent multiple selections
                  >
                    <span className="quiz-option-letter">
                      {String.fromCharCode(65 + index)}
                    </span>
                    {option}
                  </div>
                ))}
              </div>

              <div className="quiz-footer">
                <button
                  className={`quiz-btn ${
                    !selectedOption ? "quiz-btn-disabled" : ""
                  }`}
                  disabled={!selectedOption}
                  onClick={handleNextQuestion}
                >
                  {currentQuestion === quizData.length - 1 ? "Finish" : "Next"}
                </button>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default Quiz;
