import React, { useEffect, useState, useContext } from "react";
import { useNavigate, useParams, Link, NavLink } from "react-router-dom";
import { GloblInfo } from "../../App";
import { FaBook } from "react-icons/fa";
import { MdAccessTime } from "react-icons/md";
import { toast } from "react-toastify";

import { FaUserGraduate } from "react-icons/fa6";

import { MdFavoriteBorder, MdOutlineFavorite } from "react-icons/md"; // Import both icons
import { FaRegBookmark, FaBookmark } from "react-icons/fa";

import { MdDelete } from "react-icons/md";
import { FaEdit } from "react-icons/fa";
import Modal from "react-modal"; // Import react-modal
import { MdEdit } from "react-icons/md";
import { FaMale, FaFemale, FaGenderless } from "react-icons/fa"; // Import the required icons
import "./profile.css";
import "./ConfirmationPopu.css";

Modal.setAppElement("#root"); // Set the root element for accessibility

const Profile = () => {
  const { username } = useParams(); // Get the username from the URL
  const { userSession, users, userData } = useContext(GloblInfo); // Global context for session data
  const [user, setUser] = useState(null); // Local state to hold the fetched user data
  const [userUploads, setUserUploads] = useState([]); // To hold the fetched uploads
  const [filteredUploads, setFilteredUploads] = useState([]); // To hold filtered uploads
  const [activeFilter, setActiveFilter] = useState("All"); // Track the active filter
  const [savedPdfs, setSavedPdfs] = useState([]); // State to track saved PDFs
  const [saveCounts, setSaveCounts] = useState({}); // State to track save counts for each PDF
  const [modalIsOpen, setModalIsOpen] = useState(false); // State to manage modal visibility
  const [selectedPdf, setSelectedPdf] = useState(null); // State to track the PDF to delete

  const navigate = useNavigate(); // Initialize navigate
  console.log(username);

  console.log("user", user);

  // Function to open modal and set the selected PDF
  const openModal = (pdfId) => {
    setSelectedPdf(pdfId);
    setModalIsOpen(true);
  };

  // Function to close modal
  const closeModal = () => {
    setModalIsOpen(false);
  };

  // Fetch user data when the component is mounted or when the username changes
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_GET_USERS}?username=${username}`
        );
        const result = await response.json();
        if (result.status === "success") {
          setUser(result.data);
        } else {
          setUser(null); // Handle user not found
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
        setUser(null); // Handle error
      }
    };

    fetchUserData();
  }, [username]);

  // Fetch user uploads based on the username (from URL, not logged-in user)
  useEffect(() => {
    if (username) {
      // Use username from URL to fetch specific user's uploads
      fetch(`${process.env.REACT_APP_GET_UPLOADS}?username=${username}`)
        .then((response) => response.json())
        .then((data) => {
          // Store only uploads for the searched user (from the username in the URL)
          setUserUploads(data.filter((upload) => upload.username === username));
          setFilteredUploads(
            data.filter((upload) => upload.username === username)
          ); // Initially show all uploads for the searched user
        })
        .catch((error) => {
          console.error("Error fetching user uploads:", error);
        });
    }
  }, [username]); // Trigger this whenever the URL's username changes

  // Fetch saved PDFs for the logged-in user
  useEffect(() => {
    if (userData && userData.id) {
      fetch(`${process.env.REACT_APP_SAVE_PDF}?user_id=${userData.id}`)
        .then((response) => response.json())
        .then((data) => {
          setSavedPdfs(data); // Store saved PDFs for the logged-in user
        })
        .catch((error) => {
          console.error("Error fetching saved PDFs:", error);
        });
    }
  }, [userData]);

  useEffect(() => {
    filteredUploads.forEach((item) => {
      fetchSaveCount(item.id); // Trigger fetching the save count for each PDF by its ID
    });
  }, [filteredUploads]);
  const fetchSaveCount = async (pdfId) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_SAVE_PDF}?pdf_id=${pdfId}`
      );
      const data = await response.json();

      // Check if save_count is returned from the API
      if (data.save_count !== undefined) {
        setSaveCounts((prevCounts) => ({
          ...prevCounts,
          [pdfId]: data.save_count, // Set the save count for the specific PDF
        }));
      } else {
        console.error(`Save count not found for PDF ${pdfId}`);
      }
    } catch (err) {
      console.error(`Error fetching save count for PDF ${pdfId}:`, err);
    }
  };
  useEffect(() => {
    setActiveFilter("All");
    setFilteredUploads(userUploads); // Initially show all uploads for the searched user
  }, [userUploads]);

  const handleFilterChange = (filter) => {
    setActiveFilter(filter);

    if (filter === "Notes") {
      // Show only the searched user's uploads in the "Notes" category
      setFilteredUploads(
        userUploads.filter((upload) => upload.category === "Notes")
      );
    } else if (filter === "Old_Paper") {
      // Show only the searched user's uploads in the "Old Paper" category
      setFilteredUploads(
        userUploads.filter((upload) => upload.category === "Old_Paper")
      );
    } else if (filter === "Saved") {
      // Show only saved PDFs for the logged-in user, excluding the searched user's own uploads
      setFilteredUploads(
        savedPdfs.filter((saved) => saved.username !== username)
      );
    } else if (filter === "All") {
      // Show all uploaded PDFs by the searched user (based on username from URL)
      setFilteredUploads(userUploads);
    }
  };

  const handleClick = async (item) => {
    try {
      // Increment the view count by calling the PHP API
      const response = await fetch(process.env.REACT_APP_UPDATE_VIEWS, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ id: item.id }), // Send the PDF ID to the backend
      });

      const data = await response.json();

      if (data.message === "View count updated successfully") {
        // After updating the view count, navigate to the PDF detail page
        navigate(`/pdf/${item.id}`, {
          state: {
            pdfItem: item,
          },
        });
      } else {
        console.error("Error updating view count: ", data.message);
      }
    } catch (err) {
      console.error("Error updating view count: ", err);
    }
  };

  if (!user) {
    return (
      <div className="error-container">
        <div className="error-gif">
          <img src="https://i.postimg.cc/2yrFyxKv/giphy.gif" alt="gif_ing" />
        </div>
        <div className="error-content">
          <h1 className="error-heading">Sorry..</h1>
          <p className="error-message">{username}, This User Not Found</p>
        </div>
      </div>
    );
  }
  console.log("userSession", userSession);

  // Function to toggle between saving and unsaving a PDF
  const handleToggleSave = async (pdfId) => {
    if (!userSession) {
      // Show error if user is not logged in
      toast.error("Please log in first to save this PDF!", {
        autoClose: 3000,
      });
      return; // Prevent further execution
    }

    const userId = userData.id;

    // Check if the PDF is already saved by the user
    const isAlreadySaved = savedPdfs.some((savedPdf) => savedPdf.id === pdfId);

    if (isAlreadySaved) {
      // If the PDF is already saved, unsave it
      await handleUnsave(pdfId);
    } else {
      // If the PDF is not saved, save it
      await handleSave(pdfId);
    }
  };

  // Function to handle saving a PDF
  const handleSave = async (pdfId) => {
    const userId = userData.id;
    console.log(
      "user_id",
      userId,
      "pdf_id:",
      pdfId,
      "uploader_username:",
      username
    );

    // Optimistic UI update for saving a PDF
    setSavedPdfs((prevSaved) => [...prevSaved, { id: pdfId }]); // Mark as saved immediately
    setSaveCounts((prevCounts) => ({
      ...prevCounts,
      [pdfId]: (prevCounts[pdfId] || 0) + 1, // Increment save count immediately
    }));

    try {
      const response = await fetch(process.env.REACT_APP_SAVE_PDF, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          user_id: userId,
          pdf_id: pdfId,
          uploader_username: username,
        }),
      });

      const data = await response.json();

      if (data.message !== "PDF saved successfully") {
        console.error(`Error saving PDF: ${data.message}`);
        // Revert changes if saving failed
        setSavedPdfs((prevSaved) =>
          prevSaved.filter((savedPdf) => savedPdf.id !== pdfId)
        );
        setSaveCounts((prevCounts) => ({
          ...prevCounts,
          [pdfId]: (prevCounts[pdfId] || 1) - 1, // Revert save count
        }));
      }
    } catch (err) {
      console.error("Error saving PDF:", err);
      // Revert changes if an error occurs
      setSavedPdfs((prevSaved) =>
        prevSaved.filter((savedPdf) => savedPdf.id !== pdfId)
      );
      setSaveCounts((prevCounts) => ({
        ...prevCounts,
        [pdfId]: (prevCounts[pdfId] || 1) - 1,
      }));
    }
  };

  // Function to handle unsaving a PDF
  const handleUnsave = async (pdfId) => {
    const userId = userData.id;

    // Optimistic UI update for unsaving a PDF
    setSavedPdfs((prevSaved) =>
      prevSaved.filter((savedPdf) => savedPdf.id !== pdfId)
    ); // Mark as unsaved immediately
    setSaveCounts((prevCounts) => ({
      ...prevCounts,
      [pdfId]: (prevCounts[pdfId] || 1) - 1, // Decrease save count immediately
    }));

    try {
      const response = await fetch(process.env.REACT_APP_SAVE_PDF, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ user_id: userId, pdf_id: pdfId }),
      });

      const data = await response.json();

      if (data.message !== "PDF unsaved successfully") {
        console.error(`Error unsaving PDF: ${data.message}`);
        // Revert changes if unsaving failed
        setSavedPdfs((prevSaved) => [...prevSaved, { id: pdfId }]);
        setSaveCounts((prevCounts) => ({
          ...prevCounts,
          [pdfId]: (prevCounts[pdfId] || 0) + 1, // Revert save count
        }));
      }
    } catch (err) {
      console.error("Error unsaving PDF:", err);
      // Revert changes if an error occurs
      setSavedPdfs((prevSaved) => [...prevSaved, { id: pdfId }]);
      setSaveCounts((prevCounts) => ({
        ...prevCounts,
        [pdfId]: (prevCounts[pdfId] || 0) + 1,
      }));
    }
  };

  const handleDelete = async () => {
    if (!selectedPdf) return; // Ensure there's a selected PDF

    try {
      const response = await fetch(process.env.REACT_APP_DELETE_PROFILE, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ pdf_id: selectedPdf }), // Send the pdf_id in the body
      });

      if (!response.ok) {
        throw new Error("Failed to delete PDF");
      }

      const data = await response.json();
      console.log(data.message);

      if (data.message === "PDF deleted successfully") {
        // Handle success (e.g., remove the deleted PDF from the UI)
        console.log("PDF deleted successfully");
        setUserUploads((prevUploads) =>
          prevUploads.filter((upload) => upload.id !== selectedPdf)
        );
        closeModal(); // Close the modal after successful deletion
      } else {
        console.error("Deletion error:", data.message);
      }
    } catch (error) {
      console.error("Error deleting PDF:", error);
    }
  };

  const handleEdit = (item) => {
    console.log("Editing PDF with ID:", item.id);

    // Navigate to the edit page and pass the necessary PDF data
    navigate(`/pdf/${item.id}/edit`, {
      state: {
        pdfItem: item, // Pass the PDF item data, which contains pdf_id and other info
        userId: userData.id, // Ensure the user ID is passed as well
      },
    });
  };

  return (
    <div className="header__wrapper">
      <header />
      {
        <div className="cols__container">
          <div className="left__col">
            <div className="img__container">
              <img
                src={`${process.env.REACT_APP_BASE_URL}${
                  user.profile_image || "img/user.jpg"
                }`}
                alt="Profile"
              />
              <span />
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "end",
                justifyContent: "center",
                gap: "10px",
              }}
            >
              <h2>{user.name || "Name"}</h2>
              {userSession && userSession.username === username && (
                <Link to={`/profile/${username}/edit`}>
                  {/* <button className="edit-profile-btn">Edit my profile</button> */}
                  <MdEdit
                    style={{ marginBottom: "14px" }}
                    size={"23px"}
                    color="#0091FF"
                  />
                </Link>
              )}
            </div>
            <ul className="about">
              <li>
                <span>
                  {" "}
                  {
                    userUploads.filter((upload) => upload.username === username)
                      .length
                  }
                </span>{" "}
                User uploads
              </li>
              <li>
                <span>
                  {
                    userUploads.filter(
                      (upload) =>
                        upload.category === "Notes" &&
                        upload.username === username
                    ).length
                  }
                </span>{" "}
                Notes
              </li>
              <li>
                <span>
                  {
                    userUploads.filter(
                      (upload) => upload.category === "Old_Paper"
                    ).length
                  }
                </span>{" "}
                Old Papers
              </li>
            </ul>
            <div
              style={{
                marginTop: "7px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "20px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "10px",
                }}
              >
                <p>{user.profession || " "}</p>
                <p>
                  {user.gender === "Male" && <FaMale size={"15px"} />}{" "}
                  {/* Show male icon if gender is male */}
                  {user.gender === "Female" && <FaFemale size={"15px"} />}{" "}
                  {/* Show female icon if gender is female */}
                  {user.gender === "Other" && (
                    <FaGenderless size={"15px"} />
                  )}{" "}
                  {/* Show other icon if gender is other */}
                </p>
              </div>
              {/* <div>
                <p>{user.email || "anna@example.com"}</p>
              </div> */}
            </div>

            {/* <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                marginTop: "8px",
                gap: "5px",
              }}
            >
              <a
                style={{ color: "#0091FF" }}
                href={
                  user.link_1.startsWith("http")
                    ? user.link_1
                    : `http://${user.link_1}`
                }
                target="_blank" // Opens the link in a new tab
                rel="noopener noreferrer" // For security reasons, prevents issues with `target="_blank"`
              >
                {user.link_1 || ``}
              </a>
              <a
                href={
                  user.link_1.startsWith("http")
                    ? user.link_1
                    : `http://${user.link_2}`
                }
                target="_blank" // Opens the link in a new tab
                rel="noopener noreferrer" // For security reasons, prevents issues with `target="_blank"`
              >
                {user.link_2 || ``}
              </a>
            </div> */}
            <div className="content">
              <p>
                {user.bio
                  ? user.bio.split(" ").slice(0, 10).join(" ") +
                    (user.bio.split(" ").length > 10 ? "..." : "")
                  : ""}
              </p>
            </div>
          </div>

          <div className="right__col">
            <nav>
              <ul>
                <li>
                  <button
                    onClick={() => handleFilterChange("All")}
                    className={activeFilter === "All" ? "active" : ""}
                  >
                    All Posts (
                    {
                      userUploads.filter(
                        (upload) => upload.username === username
                      ).length
                    }
                    )
                  </button>
                </li>
                <li>
                  <button
                    onClick={() => handleFilterChange("Notes")}
                    className={activeFilter === "Notes" ? "active" : ""}
                  >
                    Notes
                  </button>
                </li>
                <li>
                  <button
                    onClick={() => handleFilterChange("Old_Paper")}
                    className={activeFilter === "Old_Paper" ? "active" : ""}
                  >
                    Old Papers
                  </button>
                </li>
                <li>
                  <button
                    onClick={() => handleFilterChange("Saved")}
                    className={activeFilter === "Saved" ? "active" : ""}
                  >
                    My Saved PDFs (
                    {
                      savedPdfs.filter((saved) => saved.username !== username)
                        .length
                    }
                    )
                  </button>
                </li>
              </ul>
            </nav>

            <div className="photos">
              <div className={filteredUploads.length > 0 ? "pdf-grid" : ""}>
                {filteredUploads.length > 0 ? (
                  filteredUploads.map((item) => (
                    <div
                      key={item.id}
                      // onClick={() => handleClick(item)} // Pass the entire item to handleClick
                      className="box rounded-lg shadow-shadow1 bg-white"
                    >
                      <div className="images rounded-t-lg relative overflow-hidden  w-full">
                        <img
                          src={item.image_path} // Use the image path from the API
                          onClick={() => handleClick(item)} // Pass the entire item to handleClick
                          alt={item.title}
                          style={{ height: "250px" }}
                          className="rounded-t-lg object-cover w-full transition ease-in-out delay-150 cursor-pointer hover:scale-125 duration-300"
                        />
                        <div className="categ flex gap-4 absolute top-0 m-3">
                          <span className="text-[14px] bg-blue-700 p-1 px-3 text-white rounded-[5px] shadow-md">
                            {item.subject}
                          </span>
                          <span className="text-[14px] bg-pink-700 p-1 px-3 text-white rounded-[5px] shadow-md">
                            <h3
                              className="text-black"
                              style={{ fontSize: "15px", color: "white" }}
                            >
                              {item.course.includes("(")
                                ? item.course.match(/\(([^)]+)\)/)[1]
                                : item.course}
                            </h3>
                          </span>
                        </div>
                      </div>
                      <div className="text p-3">
                        <div className="flex justify-between items-center">
                          <div className="flex items-center">
                            <FaBook />
                            <span className="text-[14px] ml-2">
                              {item.category}
                            </span>
                          </div>
                          <div className="flex items-center">
                            <MdAccessTime className="text-orange-500" />
                            <span className="text-[14px] ml-2">
                              {item.created_at.split(" ")[0]}
                            </span>
                          </div>
                        </div>

                        <h3
                          className="text-black font-medium "
                          style={{
                            marginTop: "10px",
                            color: "blue",
                            // marginBottom: "-10px",
                            fontSize: "23px",
                          }}
                        >
                          {item.title.split(" ").slice(0, 7).join(" ")}
                          {item.title.split(" ").length > 7 ? "..." : ""}
                        </h3>
                        <h3 className="text-black font-medium " style={{}}>
                          {item.description.split(" ").slice(0, 7).join(" ")}
                          {item.description.split(" ").length > 7 ? "..." : ""}
                        </h3>
                        <div style={{ marginBottom: "15px" }}>
                          <h3
                            className="text-black"
                            style={{ fontSize: "15px" }}
                          >
                            {item.college.split("(")[0].trim()}
                          </h3>
                          <div
                            style={{
                              display: "flex",
                              gap: "30px",
                              marginTop: "10px",
                            }}
                          >
                            <span className="text-[14px] bg-blue-700 p-1 px-3 text-white rounded-[5px] shadow-md">
                              Semester : {item.semester}
                            </span>
                            <span className="text-[14px] bg-pink-700 p-1 px-3 text-white rounded-[5px] shadow-md">
                              year : {item.year}
                            </span>
                          </div>
                        </div>

                        <div className="user flex items-center">
                          {/* <img
                      className="rounded-full"
                      src={`http://localhost/notesmafia/${item.profile_image}`}
                      alt={item.username}
                    /> */}
                          <FaUserGraduate />
                          <NavLink
                            to={`/profile/${item.username}`}
                            className="text-[14px] ml-2"
                            style={{ fontSize: "13px" }}
                          >
                            <span>{item.username}</span>
                          </NavLink>
                        </div>
                      </div>
                      {/* Save Icon for Saving PDF */}
                      <div className="flex items-center justify-between border-t border-gray-200 p-3">
                        <div style={{ display: "flex", gap: "10px" }}>
                          {savedPdfs.some(
                            (savedPdf) => savedPdf.id === item.id
                          ) ? (
                            <FaBookmark
                              style={{ fontSize: "28px", color: "blue" }}
                              className="cursor-pointer"
                              onClick={() =>
                                handleToggleSave(item.id, item.username)
                              } // Toggle between save/unsave
                            />
                          ) : (
                            <FaRegBookmark
                              style={{ fontSize: "28px" }}
                              className="cursor-pointer"
                              onClick={() =>
                                handleToggleSave(item.id, item.username)
                              } // Toggle between save/unsave
                            />
                          )}
                          <span>
                            {saveCounts[item.id] !== undefined
                              ? saveCounts[item.id]
                              : 0}
                          </span>
                        </div>
                        <span>views: {item.views}</span>
                      </div>
                      {/* Show Edit and Delete buttons */}
                      {userData && userData.username === item.username && (
                        <>
                          <div className="flex items-center justify-between border-t border-gray-200 p-3">
                            <div
                              style={{
                                display: "flex",
                                gap: "10px",
                                width: "100%",
                              }}
                            >
                              <button
                                style={{
                                  width: "100%",
                                  display: "flex", // Use flexbox to align items
                                  justifyContent: "center", // Center horizontally
                                  alignItems: "center", // Center vertically
                                  padding: "10px", // Add padding for better spacing
                                  backgroundColor: "transparent", // Remove background
                                  border: "none", // Remove border
                                  cursor: "pointer", // Change cursor to pointer
                                  transition:
                                    "background-color 0.3s ease, transform 0.3s ease", // Smooth transition for background and scaling
                                }}
                                onClick={() => handleEdit(item)} // Single onClick handler
                                onMouseOver={(e) => {
                                  e.currentTarget.style.backgroundColor =
                                    "#0091ff91"; // Light red background on hover
                                  e.currentTarget.style.transform =
                                    "scale(1.1)"; // Slightly enlarge on hover
                                }}
                                onMouseOut={(e) => {
                                  e.currentTarget.style.backgroundColor =
                                    "transparent"; // Reset background on mouse out
                                  e.currentTarget.style.transform = "scale(1)"; // Reset scale on mouse out
                                }}
                              >
                                <FaEdit color="#0091FF" size={24} />
                              </button>

                              <button
                                style={{
                                  width: "100%",
                                  display: "flex", // Use flexbox to align items
                                  justifyContent: "center", // Center horizontally
                                  alignItems: "center", // Center vertically
                                  padding: "10px", // Add padding for better spacing
                                  backgroundColor: "transparent", // Remove background
                                  border: "none", // Remove border
                                  cursor: "pointer", // Change cursor to pointer
                                  transition:
                                    "background-color 0.3s ease, transform 0.3s ease", // Smooth transition for background and scaling
                                }}
                                onClick={() => openModal(item.id)} // Open modal before deletion
                                onMouseOver={(e) => {
                                  e.currentTarget.style.backgroundColor =
                                    "#ffe5e5"; // Light red background on hover
                                  e.currentTarget.style.transform =
                                    "scale(1.1)"; // Slightly enlarge on hover
                                }}
                                onMouseOut={(e) => {
                                  e.currentTarget.style.backgroundColor =
                                    "transparent"; // Reset background on mouse out
                                  e.currentTarget.style.transform = "scale(1)"; // Reset scale on mouse out
                                }}
                              >
                                <MdDelete color="red" size={24} />
                              </button>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  ))
                ) : (
                  <div className="center-content">
                    <section class="space-animation">
                      <div class="stars"></div>
                      <div class="stars"></div>
                      <div class="stars"></div>
                      <div class="stars"></div>
                      <div class="stars"></div>
                      <div class="stars"></div>
                      <div class="spaceship">
                        <img
                          src="https://cdn-icons-png.flaticon.com/512/736/736175.png"
                          alt="Spaceship"
                        />
                      </div>
                      <h2>File Not Found Uploaded</h2>
                    </section>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      }
      {/* Modal for Delete Confirmation */}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Delete Confirmation"
        className="modal-confirm"
        overlayClassName="modal-overlay"
      >
        <div id="myModal" className="modal fade in">
          <div className="modal-dialog modal-confirm">
            <div className="modal-content">
              <div className="modal-header">
                <div className="icon-box">
                  <i className="material-icons">
                    <img
                      src="https://th.bing.com/th/id/OIP.92dI8xkxRD2kYsiCHM9AzwHaEc?rs=1&pid=ImgDetMain"
                      alt=""
                    />
                  </i>
                </div>
                <h4 className="modal-title w-100">Confirm Deletion</h4>
              </div>
              <div className="modal-body">
                <p className="text-center">
                  Are you sure you want to delete this PDF? This process cannot
                  be undone.
                </p>
              </div>
              <div className="modal-footer">
                <button
                  className="btn btn-danger btn-block"
                  onClick={closeModal}
                >
                  Cancel
                </button>
                <button
                  className="btn btn-success btn-block"
                  onClick={handleDelete}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Profile;
