import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import * as pdfjsLib from "pdfjs-dist/build/pdf";
import "pdfjs-dist/build/pdf.worker.entry";
import "./PdfDetails.css";

const PdfDetails = () => {
  const { pdfItem } = useLocation().state || {};
  const [pdfImages, setPdfImages] = useState([]);
  const [loading, setLoading] = useState(true); // Loading state for progress
  const [progress, setProgress] = useState(0); // Progress percentage

  const addWatermark = (context, canvas) => {
    const text = "NOTESMANIA";
    context.font = "20px Arial";
    context.fillStyle = "rgba(255, 0, 0, 0.1)"; // Light red watermark with 20% opacity
    context.textAlign = "center";
    context.textBaseline = "middle";

    const centerX = canvas.width / 2;
    const centerY = canvas.height / 2;

    context.save();
    context.translate(centerX, centerY);
    context.rotate(-Math.PI / 7); // Rotate text diagonally
    for (let y = -canvas.height; y < canvas.height; y += 100) {
      for (let x = -canvas.width; x < canvas.width * 2; x += 300) {
        context.fillText(text, x, y);
      }
    }
    context.restore();
  };

  useEffect(() => {
    if (pdfItem && pdfItem.file_path) {
      const pdfUrl = `https://notesmafia.taazaekhabar.site/serve-pdf.php?file=${encodeURIComponent(
        pdfItem.file_path
      )}`;
      renderPdfAsImagesWithWatermark(pdfUrl);
    }
  }, [pdfItem]);

  const renderPdfAsImagesWithWatermark = async (url) => {
    try {
      const loadingTask = pdfjsLib.getDocument(url);
      const pdf = await loadingTask.promise;

      const images = [];
      for (let pageNumber = 1; pageNumber <= pdf.numPages; pageNumber++) {
        const page = await pdf.getPage(pageNumber);
        const viewport = page.getViewport({ scale: 1.2 });

        const canvas = document.createElement("canvas");
        const context = canvas.getContext("2d");
        canvas.width = viewport.width;
        canvas.height = viewport.height;

        const renderContext = { canvasContext: context, viewport: viewport };
        await page.render(renderContext).promise;

        addWatermark(context, canvas);

        const imageUrl = canvas.toDataURL("image/png");
        images.push(imageUrl);

        // Update progress percentage
        setProgress(Math.round((pageNumber / pdf.numPages) * 100));
      }

      setPdfImages(images);
      setLoading(false); // Mark loading as complete
    } catch (error) {
      console.error("Error rendering PDF:", error);
      setLoading(false); // Stop loading even if there's an error
    }
  };

  if (!pdfItem) {
    return <div>No PDF data available</div>;
  }

  return (
    <div className="pdf-details-container">
      {/* User Profile Section */}
      <div className="user-profile">
        <img
          className="user-avatar"
          src="https://via.placeholder.com/100"
          alt="User Avatar"
        />
        <div className="user-info">
          <h2>{pdfItem.username}</h2>
          <p>{pdfItem.college}</p>
        </div>
      </div>

      {/* PDF Metadata Section */}
      <div className="pdf-metadata">
        <div className="metadata-item">
          <strong>Category:</strong>
          <span>{pdfItem.category}</span>
        </div>
        <div className="metadata-item">
          <strong>Course:</strong>
          <span>{pdfItem.course}</span>
        </div>
        <div className="metadata-item">
          <strong>Semester:</strong>
          <span>{pdfItem.semester}</span>
        </div>
        <div className="metadata-item">
          <strong>Subject:</strong>
          <span>{pdfItem.subject}</span>
        </div>
        <div className="metadata-item">
          <strong>Tags:</strong>
          <span>{pdfItem.tags}</span>
        </div>
      </div>

      {/* Progress Bar */}
      {loading && (
        <>
          <div className="progress-bar-container">
            <p>Loading... {progress}%</p>
            <div className="progress-bar">
              <div
                className="progress-bar-fill"
                style={{ width: `${progress}%` }}
              ></div>
            </div>
          </div>{" "}
          <div className="main-loading">
            <div className="spacecraft-loading">
              <span>L</span>
              <span>O</span>
              <span>A</span>
              <span>D</span>
              <span>I</span>
              <span>N</span>
              <span>G</span>
              <div className="orbit"></div>
            </div>
          </div>
        </>
      )}

      {/* Render PDF Images */}
      {!loading && (
        <div className="pdf-grid">
          {pdfImages.map((image, index) => (
            <div
              key={index}
              className="pdf-image-container"
              onContextMenu={(e) => e.preventDefault()}
            >
              <img src={image} alt={`Page ${index + 1}`} />
              {/* Page Number */}
              <div className="page-number">Page {index + 1}</div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default PdfDetails;
