import React from "react";
import logImg from "../assets/images/logo-black.png";
import { BsFacebook, BsTwitter, BsInstagram, BsLinkedin } from "react-icons/bs";
import { NavLink } from "react-router-dom";

export const Footer = () => {
  return (
    <footer className="bg-[#F3F4F8] mt-10 border-t border-gray-200">
      <div className=" mx-auto px-6 lg:px-20 py-12 flex flex-wrap justify-between gap-8">
        {/* Logo and Description */}
        <div className="flex flex-col space-y-4 max-w-[300px]">
          <img src={logImg} alt="Logo" className="w-20" />
          <p>@2024</p>
          <div className="flex gap-4">
            <a
              href="#"
              aria-label="Facebook"
              className="text-gray-600 hover:text-black transition"
            >
              <BsFacebook size={20} />
            </a>
            <a
              href="#"
              aria-label="Twitter"
              className="text-gray-600 hover:text-black transition"
            >
              <BsTwitter size={20} />
            </a>
            <a
              href="#"
              aria-label="Instagram"
              className="text-gray-600 hover:text-black transition"
            >
              <BsInstagram size={20} />
            </a>
            <a
              href="#"
              aria-label="LinkedIn"
              className="text-gray-600 hover:text-black transition"
            >
              <BsLinkedin size={20} />
            </a>
          </div>
        </div>

        {/* Company Links */}
        <div className="flex flex-col space-y-2">
          <h4 className="text-black text-sm font-semibold mb-4">Company</h4>
          <NavLink
            to="/blog"
            className="text-gray-600 text-[14px] hover:text-black transition"
          >
            Blog
          </NavLink>
          <NavLink
            to="/news"
            className="text-gray-600 text-[14px] hover:text-black transition"
          >
            Latest News
          </NavLink>
        </div>

        {/* Contact Links */}
        <div className="flex flex-col space-y-2">
          <h4 className="text-black text-sm font-semibold mb-4">Contact</h4>
          <NavLink
            to="/contact"
            className="text-gray-600 text-[14px] hover:text-black transition"
          >
            Contact Us
          </NavLink>
          <NavLink
            to="/team"
            className="text-gray-600 text-[14px] hover:text-black transition"
          >
            Our Team
          </NavLink>
        </div>

        {/* Policy Links */}
        <div className="flex flex-col space-y-2">
          <h4 className="text-black text-sm font-semibold mb-4">
            Website Policies
          </h4>
          <NavLink
            to="/about"
            className="text-gray-600 text-[14px] hover:text-black transition"
          >
            About Us
          </NavLink>
          <NavLink
            to="/privacy-policy"
            className="text-gray-600 text-[14px] hover:text-black transition"
          >
            Privacy Policy
          </NavLink>
        </div>
      </div>

      <div className="bg-[#E6E6E6] py-4 text-center text-gray-600 text-sm">
        &copy; {new Date().getFullYear()} NotesMafia. All Rights Reserved.
      </div>
    </footer>
  );
};
